body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

ul,
li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.ant-layout{
  background: #f0f2f5
}

.ant-layout-header {
  background: #373d41 !important;
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #373d41 !important;
}

.ant-layout-sider {
  background: #333744 !important;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  background-color: #42485b;
  color: rgba(255, 255, 255, 0.67) !important;
}

.ant-layout-has-sider .ant-menu-submenu .ant-menu-item {
  background-color: #333744;
  color: rgba(255, 255, 255, 0.67) !important;
}

.ant-layout-has-sider .ant-menu-submenu .ant-menu-item:hover {
  background-color: #42485b;
}

.ant-menu-submenu-inline .ant-menu-submenu-title:hover {
  color: #fff !important;
  background-color: rgba(24, 144, 255, 0.7) !important;
}

.ant-menu-submenu-inline
.ant-menu-submenu-title:hover
.ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline
.ant-menu-submenu-title:hover
.ant-menu-submenu-arrow:after {
  background: linear-gradient(to right, #fff, #fff) !important;
}

.ant-layout-has-sider .ant-menu-item.ant-menu-item-selected {
  background-color: rgba(24, 144, 255, 0.7) !important;
  color: #fff !important;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: 13px !important;
}

.ant-menu-submenu-open .ant-menu-submenu-title {
  color: #fff !important;
}

.ant-layout-header .logo {
  float: left;
  width: 200px;
  height: 100%;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-layout-header .logo img {
  height: 100%;
}

.hidden {
  display: none !important;
}

.tools-wrapper {
  padding: 0;
}

.tools-wrapper .ant-btn {
  margin-top: 8px;
  margin-right: 8px;
}

.tools-wrapper .ant-btn:last-child {
  margin-right: 0;
}

.table-wrapper {
  margin-top: 20px;
}

.table-wrapper .ant-table {
  overflow: auto;
}

.table-wrapper .ant-table tbody tr {
  cursor: pointer;
}

.table-wrapper .ant-table-title {
  color: red;
}

.table-wrapper .ant-table-bordered .ant-table-thead > tr > th {
  white-space: nowrap;
}

.ant-select-dropdown-menu-item {
  white-space: normal !important;
}

.detail-form-wrapper
> .ant-row
> .ant-form-item-label.ant-col-xs-24.ant-col-sm-8 {
  width: 300px;
  margin-right: 10px;
}

.detail-form-wrapper .readonly-text {
  padding: 9px 11px;
  white-space: normal;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.5;
  box-sizing: border-box;
}

.detail-form-wrapper
.ant-upload-list-item-done
.ant-upload-list-item-info
> span {
  height: 100%;
}

.build-modal-detail .detail-form-wrapper {
  width: auto;
}

.textarea-normalArea {
  resize: none;
}

.ant-form-item-control {
  z-index: 99;
}

.restDay-div {
  width: 100%;
  text-align: center;
}

.ant-back-top {
  z-index: 1000;
}

.previewImg-wrap .img-wrap {
  max-width: 100%;
  max-height: 600px;
  text-align: center;
}

.previewImg-wrap .img-wrap img {
  max-width: 100%;
  max-height: 600px;
  vertical-align: bottom;
  margin: 0 auto;
}

.ant-carousel .slick-slide {
  text-align: center;
  overflow: hidden;
}

.ant-carousel .slick-dots li button {
  background: #000 !important;
}

.down-wrap {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.cform-item-btn .ant-form-item-label label {
  visibility: hidden;
}

.ant-menu-horizontal {
  white-space: normal !important;
}

.detail-language-tab .detail-language-tab-line-in,
.detail-language-tab .detail-language-tab-line-out {
  cursor: pointer;
}

/*UI修改部分*/
/*列表部分*/
.overall-situation-btn-search {
  width: 68px;
  height: 32px;
  line-height: 32px;
  background: rgba(55, 151, 255, 1);
  border-radius: 2px;
  color: #fff;
}

.overall-situation-btn-gb {
  padding-left: 10px;
  padding-right: 10px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 1px;
  background: rgba(254, 251, 255, 1);
  border: 1px solid rgba(55, 151, 255, 1);
  font-size: 12px;
  font-weight: 500;
  color: rgba(55, 151, 255, 1);
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.overall-situation-btn-reset {
  width: 68px;
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  border: 1px solid rgba(153, 153, 153, 1);
}

.overall-situation-btn-gp {
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  border: 1px solid rgba(153, 153, 153, 1);
}

.overall-situation-btn-input {
  width: 200px;
  height: 30px;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
}

.overall-situation-search-group {
  margin-top: 15px;
}

.overall-situation-table {
  border: 1px solid rgb(230, 230, 230) !important;
  border-radius: 8px !important;
}

.dashboard-layout .right-content {
  padding: 0px !important;
  margin-top: 20px !important;
  /* padding-bottom: 20px !important; */
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  color: #fff !important;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #fff !important;
  border-bottom: 0px !important;
}

.ant-dropdown-trigger {
  color: #fff !important;
}

.anticon anticon-mail {
  background: #fff !important;
}

.column-money {
  text-align: center;
}

.detail-form-wrapper
> .ant-row
> .ant-form-item-label.ant-col-xs-24.ant-col-sm-8 {
  width: 180px !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  width: 33px;
  height: 33px;
  background: rgba(240, 242, 245, 1);
  border-radius: 1px;
}

.ant-pagination-item {
  min-width: 34px !important;
  height: 34px !important;
  line-height: 34px !important;
  border-radius: 1px;
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #1890ff;
}

/*.ant-form {*/
/*  margin-top: 20px !important;*/
/*}*/

.print-form-wrapper p {
  margin-bottom: 0;
}

.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
}
.ant-drawer-body {
  flex-grow: 1;
  overflow: auto;
}

.contractRulesWrapper .ant-col-8 {
  display: inline-block;
  float: none;
}

.detail-title{
  font-size: 16px;
  position: relative;
  color: #222;
  padding: 10px 14px;
  margin-bottom: 24px;
}
.detail-title i{
  display: block;
  width: 4px;
  height: 20px;
  border-radius: 2px;
  background-color: rgb(55, 151, 255);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.detail-underline{
  width: 100%;
  border-bottom: 1px solid #E6E6E6;
}
.detail-title-hasUnderline{
  border-bottom: 1px solid #E6E6E6;
}
.previewImg-wrap .audio-wrap {
  width: 400px !important;
  max-height: 600px;
  text-align: center;
}
.audio-container .audio-btn{
  display: flex;
  height: 20px;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 20px;
}
.audio-container .audio-btn img{
  width: 18px;
  height: 18px;
}
.audio-container .audio-btn img:hover{
  cursor: pointer;
}
.audio-container p{
  margin-bottom: 0;
}
.audio-container .audio-btn p{
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-left: 7px;
}
.audio-container .audio-range-wrap{
  width: 100%;
}
.audio-container .audio-range-wrap input{
  width: 100%;
}
/*.audio-container .audio-range-wrap input[type=range]:focus {*/
/*   outline: none;*/
/* }*/
/*!* 自定义进度条样式 *!*/
/*.audio-container .audio-range-wrap input[type=range] {*/
/*  -webkit-appearance: none;!*清除系统默认样式*!*/
/*  height: 6px;*/
/*  background: #EBEBEB;*/
/*  border-radius: 3px;*/
/*}*/
/*!*拖动块的样式*!*/
/*.audio-container .audio-range-wrap input[type=range]::-webkit-slider-thumb {*/
/*  -webkit-appearance: none;!*清除系统默认样式*!*/
/*  width: 12px;*/
/*  height: 12px;*/
/*  border-radius: 50%;*/
/*  background: #FFFFFF;*/
/*  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16)*/
/*}*/
.audio-container .audio-time-wrap{
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.audio-container .audio-time-wrap p{
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
}
.textBtn{
  display: inline-block;
  color: #1890ff;
  cursor: pointer;
}


.headerBox{
  height: 300px;
}
.leftBox{
  width: 50%;
  height: 300px;
  float: left;
  margin-right: 20px;
}
.rightBox{
  float: left;
  font-size: 16px;
  height: 300px;
}
.explain{
  margin-top: 100px;
  margin-left: 20px;
  width: 340px;
}
.explain .title{
  font-size: 16px;
  display: inline-block;
}
.explain .inp {
  width: 260px;
  display: inline-block;
}
.btns {
  float: right;
  margin-top: 20px;
}
.btns .leftBtn, .btns .rightBtn {
  float: left;
  width: 100px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #FFF;
  background-color: rgb(71, 138, 226);
  border: 1px solid #999;
  border-radius: 2px;
  cursor: pointer;
}
.btns .leftBtn{
  margin-right: 110px;
}
.investigateBox {
  margin-top: 30px;
  width: 100%;
  border-radius: 2px;
  margin-bottom: 30px;
}
.investigateTitle {
  height: 47px;
  padding: 10px;
  font-size: 16px;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 40px;
  position: relative;
}
.investigateTitle .tabBtns {
  position: absolute;
  top: 6px;
  left: 130px;
}
.investigateTitle .tabBtns Button {
  margin-right: 20px;
}
.investigateInp {
  padding: 20px 10px 0;
}
.investigateInp-item {
  display: inline-block;
}
.itemInp {
  width: 300px;
  margin-top: 20px;
}
.subBtns {
  height: 50px;
}
.subBtns Button {
  float: right;
  margin: 0 25px;
}
.left_menu .ant-menu{
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.investigateBox .ant-tabs-nav .ant-tabs-tab{
  margin-right: 35px;
}
